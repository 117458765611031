import styled, { css } from 'styled-components'
import { Plus } from 'styled-icons/boxicons-regular'
import { Minus } from 'styled-icons/boxicons-regular'
import Square9 from './styles/square-9'


export const iconStyles = css`
  ${Square9}

  right: 0;
  margin: 0 ${({ theme }) => theme.verticalRythm[7]} 0 0;
`

export const IconPlus = styled(Plus)`
  ${iconStyles}
`

export const IconMinus = styled(Minus)`
  ${iconStyles}
`
