import React from 'react'
import styled from 'styled-components'
import Paragraph from './elements/paragraph'

const Title = styled(Paragraph)`
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes[8]};
  line-height: ${({ theme }) => theme.fontSizes[9]};
  margin: 0;
  width: 100%;
`

export default ({ children, as }) => <Title as={as}>{children}</Title>
