import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

export default ({ children }) => (
  <Container>
    {children}
  </Container>
)
