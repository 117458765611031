import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import AstInverted from './ast-inverted'
import { IconPlus, IconMinus } from './accordion-icons'
import Container from './accordion-container'
import Title from './accordion-title'

const StyledAst = styled(AstInverted)`
  margin: 0;

  > div,
  > div > * {
    margin: 0;
  }
`

const ButtonContainer = styled.div`
  margin: 0;
`

const Button = styled.div`
  z-index: ${({ theme }) => theme.zIndeces.high};
  position: relative;
  margin: 0;
  padding: ${({ theme }) => theme.verticalRythm[8]};
  background-color: ${({ theme }) => theme.colors.gray[50]};
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  overflow: hidden;
  text-align: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ active, theme }) => active ? `0 10px 20px 0 ${theme.colors.gray[300]}` : 'none'};
  will-change: box-shadow;
  transition: box-shadow ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};
`

const Content = styled.div`
  opacity: ${({ active }) => active ? 1 : 0};
  pointer-events: ${({ active }) => active ? 'auto' : 'none'};
  margin-bottom: ${({ theme, active }) => active ? theme.verticalRythm[9] : 0};
  overflow: hidden;
  padding: ${({ theme }) => theme.verticalRythm[8]};
  margin-top: ${({ theme }) => theme.verticalRythm[6]};
  background-color: ${({ theme }) => theme.colors.body};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ active, theme }) => active ? `0 10px 20px 0 ${theme.colors.gray[300]}` : 'none'};
  will-change: max-height, opacity, margin, box-shadow;
  transition: max-height ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default},
              opacity ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default},
              margin ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default},
              box-shadow ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};
`

export default ({ title, content, as }) => {
  const [active, setActive] = useState(``)
  const [height, setHeight] = useState(`0`)

  const contentRef = useRef(null)

  const toggle = () => {
    setActive(active === `` ? `active` : ``)
    setHeight(active === `active` ? `0` : `${contentRef.current.scrollHeight}px`)
  }

  return (
    <Container>
      <ButtonContainer active={active}>
        <Button
          onClick={toggle}
          active={active}
        >
          {
            active ?
            <IconMinus /> :
            <IconPlus />
          }
          <Title as={as} active={active}>
            {title}
          </Title>
        </Button>
      </ButtonContainer>
      <Content
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
        active={active}
      >
        <StyledAst ast={content} />
      </Content>
    </Container>
  )
}
