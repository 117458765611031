import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Ast from '../components/ast'
import Seo from '../components/seo'
import FaqList from '../components/faq-list'
import Heading from '../components/page-heading'

export default ({ data: { allContentfulFaqPage: { edges } } }) => {
  const { node } = edges[0]
  const { title, body, faqList } = node
  const htmlAst = body.childMarkdownRemark.htmlAst

  return (
    <Layout customSeo={`true`}>
      <Seo faqPage={node} />
      <article>
        <Heading>{title}</Heading>
        <Ast ast={htmlAst} />
        <FaqList faqList={faqList} as="h2" />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query FAQPage {
    allContentfulFaqPage {
      edges {
        node {
          title
          path {
            url
            label
          }
          body {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
          faqList: faq {
            question
            answer {
              childMarkdownRemark {
                html
                htmlAst
              }
            }
          }
        }
      }
    }
  }
`
