import React from 'react'
import { v4 } from 'uuid'
import styled from 'styled-components'
import Accordion from './accordion'

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.verticalRythm[9]};
`

export default ({ faqList, as }) => (
  <Container>
    {faqList.map(({ question, answer, active }) => {
      const htmlAst = answer.childMarkdownRemark.htmlAst

      return (
        <Accordion
          title={question}
          content={htmlAst}
          key={v4()}
          as={as}
          active={active}
        />
      )
    })}
  </Container>
)
